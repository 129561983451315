<template>
  <div id="app">
    <el-container id="all">
      <el-aside width="300px">
        <el-row>
          <el-col>
            <el-button type="primary" @click="connectWs" :disabled="isConnected">连接服务器</el-button>
            <el-button type="primary" :disabled="!isConnected" @click="switchReceive">
              {{ isReceive === false ? '开始接收' : '停止接收' }}
            </el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-form id="ws-form" ref="ws-form" :model="ws_form">
            <el-form-item>
              <el-input v-model="ws_form.host" placeholder="服务器地址" clearable>
                <template slot="prepend">ws://</template>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="ws_form.port" placeholder="端口" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="ws_form.passwd" clearable></el-input>
            </el-form-item>
          </el-form>
        </el-row>
      </el-aside>
      <el-container>
        <el-header height="150px;">
          <el-col :span="20">
            <el-form id="set-from" label-position="right" label-width="100px" ref="set-form" :model="set_form">
              <el-form-item label="接口名称:">
                <el-select v-model="set_form.header"
                           filterable
                           allow-create
                           default-first-option
                           placeholder="请选择">
                  <el-option
                    v-for="item in header_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="MAC:">
                <el-input v-model="set_form.MAC" clearable></el-input>
              </el-form-item>
              <el-form-item label="SN:">
                <el-input v-model="set_form.SN" clearable></el-input>
              </el-form-item>
              <el-form-item label="代理商:">
                <el-input v-model="set_form.clientName" clearable></el-input>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" :disabled="!isConnected" @click="setFilter">设置过滤</el-button>
          </el-col>
        </el-header>
        <el-main>
          <!--          <virtual-recycle-scroller :items="list"></virtual-recycle-scroller>-->
          <RecycleScroller
            class="scroller"
            :items="list"
            :item-size="250"
          >
            <!--            <template #before>-->
            <!--              Hey! I'm a message displayed before the items!-->
            <!--            </template>-->

            <template v-slot="{ item }">
              <div id="log-item" @click="show(item)">
                <div id="log-info">
                  <span>MAC:{{ item.data.uid }}</span>
                  <span>接口:{{ item.data.header }}</span>
                  <span>时间:{{ item.time }}</span>
                  <span>IP:{{ item.ip }}</span>
                  <span>错误:{{ item.error }}</span>
                </div>
                <div id="log-data">
                    <span v-for="(value,key) in item.data.data" :key="key">
                      {{ key }}:{{ value }}
                    </span>
                </div>
              </div>
            </template>
          </RecycleScroller>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<style lang="scss">
html, body, #app {
  height: 100%;
  margin: 0;
  padding: 0;

  #all {
    height: 100%;
  }
}
</style>
<style lang="scss" scoped>

.scroller, el-main {
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.el-aside {
  border-right: #909399 1px solid;
  padding: 20px 20px;
}

.el-header {
  margin-top: 10px;
  border-bottom: #909399 1px solid;
}

.el-row {
  margin-top: 20px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  margin-top: 20px;
  border-radius: 4px;
}

#set-from {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

#log-item {
  border: #6187ff 1px solid;
  width: 99%;
  border-radius: 4px;
  height: 245px;
  display: flex;
  //justify-content: center;
  //align-content: flex-start;

  #log-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
    width: 30%;
    margin-left: 5px;

    span {
      border-bottom: #909399 1px solid;
      text-align: left;
    }
  }

  #log-data {
    display: flex;
    flex-direction: column;
    width: 70%;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;

    span {
      width: 90%;
      border-bottom: #909399 1px solid;
      text-align: left;
    }
  }

  &:hover {
    border: #000000 2px solid;
  }
}

</style>

<script>

// import virtualRecycleScroller from '@/components/virtualRecycleScroller'
import { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

export default {
  components: {
    // virtualRecycleScroller
    RecycleScroller
  },
  data () {
    return {
      ws_form: {
        host: '',
        port: '9659',
        passwd: ''
      },
      header_options: [{
        value: 'getNewApp',
        label: 'APP升级'
      }, {
        value: 'getAndroidFirmware',
        label: '固件升级'
      }, {
        value: 'getAdvertisement',
        label: '开机广告'
      }, {
        value: 'getAppMarket',
        label: 'APP市场'
      }, {
        value: 'getMajorEvent',
        label: '主要赛事'
      }, {
        value: 'getNotification',
        label: '消息通知'
      }, {
        value: 'getVoiceRemoteControlInfo',
        label: '语音遥控信息'
      }],
      set_form: {
        header: '',
        MAC: '',
        SN: '',
        clientName: ''
      },
      list: [
        // {
        //   time: '2022/09/15 10:33:20',
        //   ip: '45.185.46.86',
        //   data: '{"header":"getAdvertisement","uid":"287994042cc8","data":{"version":"3.177","clientName":"XPLUS","name":"LauncherAD", "token":"OUY0RDc3MkQ1M0QxMUE0MDcyRTg4MTZCMjdBQjU4QTlDM0U5RkRGMDAxOEVGMzg2Q0YxRjJDMkNCODhCRDdDQjlCMTAxMUI0ODZGNzcyNzBENTRCRkY4OEUyQjA0RDE5"}}',
        //   error: 'ERROR:17'
        // },
        // {
        //   time: '2022/09/15 10:33:20',
        //   ip: '45.185.46.86',
        //   data: '{"header":"getAdvertisement","uid":"287994042cc8","data":{"version":"3.177","clientName":"XPLUS","name":"LauncherAD", "token":"OUY0RDc3MkQ1M0QxMUE0MDcyRTg4MTZCMjdBQjU4QTlDM0U5RkRGMDAxOEVGMzg2Q0YxRjJDMkNCODhCRDdDQjlCMTAxMUI0ODZGNzcyNzBENTRCRkY4OEUyQjA0RDE5"}}',
        //   error: ''
        // }
      ],
      ws: null,
      isConnected: false,
      isReceive: false,
      num: 1
    }
  },
  methods: {
    show: function (data) {
      console.log(data)
    },
    formatData: function (str) {
      console.log(1)
    },
    switchReceive: function () {
      this.isReceive = !this.isReceive
      this.ws.send(JSON.stringify({ isReceive: this.isReceive }))
    },
    setFilter: function () {
      this.ws.send(JSON.stringify(this.set_form))
    },
    connectWs: function () {
      this.ws = new WebSocket('ws://' + this.ws_form.host + ':' + this.ws_form.port + '/')
      this.ws.onopen = this.opOpen
      this.ws.onmessage = this.onMessage
      this.ws.onclose = this.onClose
      this.ws.onerror = this.onError
      console.log(this.ws)
    },
    opOpen: function (event) {
      this.ws.send(JSON.stringify({ passwd: this.ws_form.passwd }))
    },
    onMessage: function (event) {
      if (event.data === 'ok') {
        this.isConnected = true
        return
      }
      if (event.data.length > 0) {
        const dataArr = event.data.split('|')
        const pushObj = {}
        pushObj.id = this.num++
        pushObj.time = dataArr[0]
        pushObj.ip = dataArr[1]
        // eslint-disable-next-line no-eval
        pushObj.data = eval('(' + dataArr[2] + ')')
        if (dataArr.length >= 3) {
          pushObj.error = dataArr[3]
        } else {
          pushObj.error = ''
        }
        console.log(dataArr[3])
        console.log(pushObj)
        this.list.unshift(pushObj)
      }
    },
    onClose: function (event) {
      this.isConnected = false
      this.isReceive = false
    },
    onError: function (event) {
      this.onClose()
      console.log(event)
    }
  }
}
</script>
