import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import VueVirtualScroller from 'vue-virtual-scroller'
// import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.use(ElementUI)
// Vue.use(VueVirtualScroller)

// Vue.prototype.$socket = VueNativeSock
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
